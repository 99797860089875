<template>
  <div id="app">
    <div class="header">
      <van-nav-bar
        title=""
        left-arrow
        fixed
        placeholder
        :border="false"
        @click-left="onClickLeft"
        right-text="验证码登录"
        @click-right="onClickRight"
      />
      <div class="img">
        <img
          src="@/assets/img/logo.png"
          alt=""
          class="img"
          style="width: 93px; height: 93px"
        />
        <span style="font-size: 18px">Win生活</span>
      </div>
    </div>

    <div class="form">
      <van-form ref="form1" @submit="submit">
        <van-cell-group inset>
          <van-field
            v-model="form.phone"
            left-icon="iconfont1 icon-phone"
            type="number"
            placeholder="请输入您的手机号"
            name="pattern"
            :rules="[{ required: true }]"
          />
          <van-field
            v-model="form.password"
            type="password"
            left-icon="iconfont1 icon-possword"
            placeholder="请输入6-8位密码"
            :rules="[{ required: true }]"
          />
        </van-cell-group>

        <div class="" style="width: 100%; text-align: right">
          <span style="color: #fb2b53; text-align: right" @click="forget"
            >忘记密码</span
          >
        </div>

        <div class="button">
          <van-button type="primary" native-type="submit">手机登录</van-button>
        </div>
      </van-form>
    </div>
    <div class="footer">
      <van-checkbox v-model="checked"></van-checkbox>
      <span style="margin-left: 10px">登录即表示您已详细阅读并同意</span>
      <span
        style="color: #ff0000"
        @click="$router.push({ path: '/userAgreement' })"
        >《用户协议》</span
      >
      与
      <span
        style="color: #ff0000"
        @click="$router.push({ path: '/privacyPolicy' })"
        >《隐私协议》</span
      >
    </div>
  </div>
</template>

<script>
import { Toast } from "vant"
import { passwordLogin, sendQrCode, scanCode } from "@/api/login";
export default {
  data() {
    return {
      form: {
        phone: null,
        password: null,
        loginCode: '',
        appVersion: 'app'
      },
      checked: false,
      pattern: [
        {
          // 是否必填
          required: true,
          message: "错误信息",
          trigger: "onBlur/onChange",
        },
        {
          // 自定义表单校验
          validator: (val) => {
            // true:验证通过
            // false:验证不通过
            return /^1\d{10}$/.test(val);
          },
          message: "错误信息",
          trigger: "onBlur/onChange",
        },
      ],
    };
  },
  created() {
    this.$store.commit(
      "setSession",
      this.$route.query.session ||
        "$2a$10$ATmsOm7SJ9Xdajcs1/pJVeQm0cfhOiG/9dYAggpggnvm/oCoCrx.2"
    );
  },
  methods: {
    submit() {
      if (this.checked) {
        this.$refs.form1.validate().then(() => {
          // 验证通过
          this.form.loginCode = this.$route.query.invite_code
          var form1 = JSON.stringify(this.form);
          passwordLogin(form1).then((res) => {
            if (res.data.code == 0) {
              sendQrCode({
                qrCode: this.$route.query.invite_code,
                // qrCode: 'onrgY4KR',
                userId: res.data.data.id
              }).catch(() => {
                Toast('绑定失败，请重新扫码绑定')
              })

              scanCode({
                userId: res.data.data.id,
                parentId: this.$route.query.userId,
                type: this.$route.query.type
              })

              this.openApp(
                "jump?type=" +
                  this.$route.query.type +
                  "&phone=" +
                  this.form.phone +
                  "&password=" +
                  this.form.password +
                  "&userId=" +
                  this.$route.query.userId,
                true
              )
            } else {
              Toast.fail(res.data.message);
            }
          });
        });
      } else {
        Toast.fail("请详细阅读并同意《用户协议》 与 《隐私协议》");
      }
    },
    // pattern(val) {
    // 	return /^1\d{10}$/.test(val)
    // },
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
      // this.$router.go(-1)
    },
    onClickRight() {
      this.$router.push({
        path: "/password",
        query: {
          invite_code: this.$route.query.invite_code,
          userId: this.$route.query.userId
        }
      });
    },
    forget() {
      this.$router.push({
        path: "/forgetpassword",
      });
      //
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: center;
  max-width: 100%;
  overflow: hidden;
}

.img {
  width: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
  margin-top: 50px;
}

.contentInfo {
  display: flex;
  justify-content: center;
  margin: 40px 0;

  span {
    margin: 0 2px;
  }
}

.button {
  display: flex;
  justify-content: center;
}

.van-button--primary {
  width: 343px;
  height: 43px;
  background: #f52556;
  opacity: 1;
  border-radius: 22px;
  border: none;
  margin-top: 50px;
}

.van-nav-bar {
  background-color: transparent;
}

/deep/ .van-nav-bar__text {
  color: black;
  font-size: 17px;
}

/deep/.van-nav-bar__left .van-icon-arrow-left {
  color: black;
}

.van-cell {
  background-color: #f8f8f8;
  border-radius: 18px;
  width: 100%;
  margin-top: 3%;
}

.form {
  display: flex;
  width: 96%;
  flex-direction: column;
  justify-content: end;
  margin: 20px 2%;
  margin-bottom: 0;
}

.footer {
  margin: 2%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 25px;
}

/deep/ .van-field__left-icon .van-icon,
.van-field__right-icon .van-icon {
  font-size: 20px;
  font-weight: bold;
  color: #999999;
}

/deep/ .van-field__control {
  font-size: 16px;
  margin-left: 8px;
}
</style>
